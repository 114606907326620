<template>
  <div :class="{ 'search-active': searchActive }">
    <!-- Loading Indicator -->
    <div v-if="loading && (!videos || videos.length === 0)" class="loading">
      Loading...
    </div>

    <div v-else>
      <!-- Fixed Header Container -->
      <div class="categories-container">
        <!-- Branding with Favicon -->
        <div class="branding" @click="goToHome">
          <img
            src="/favicon.ico"
            alt="KalpX Favicon"
            class="favicon"
            width="32"
            height="32"
          />
          <h1>
            {{
              searchActive
                ? `KalpX - ${activeSearchQuery}`
                : selectedCategory
                  ? selectedLanguage
                    ? `KalpX - ${categories.find((c) => c.id === selectedCategory)?.name}:${selectedLanguage}`
                    : `KalpX - ${categories.find((c) => c.id === selectedCategory)?.name}`
                  : selectedLanguage
                    ? `KalpX - ${selectedLanguage}`
                    : 'KalpX - Connect to Your Roots'
            }}
          </h1>
        </div>

        <!-- Cancel Button (X) -->
        <button
          v-if="searchActive || selectedCategory || selectedLanguage"
          class="cancel-button"
          @click="goToHome"
        >
          ✕
        </button>

        <!-- Search Bar and Kids Hub -->
        <div class="search-kids-container">
          <div class="search-bar-container">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search videos..."
              class="search-input"
              @keyup.enter="debouncedNavigateToSearch"
            />
            <button class="search-icon" @click="debouncedNavigateToSearch">
              <span>🔍</span>
            </button>
          </div>
          <button
            class="kids-hub-toggle"
            @click="toggleChildAnimeFilter"
            :class="{ 'active-filter': isChildAnimeFilterActive }"
          >
            {{ isChildAnimeFilterActive ? 'Kids Hub On' : 'Kids Hub Off' }}
          </button>
        </div>

        <!-- Categories and Languages -->
        <div class="filters" :class="{ hidden: searchActive }">
          <div v-if="categories && categories.length" class="categories-row">
            <div class="category-wrapper">
              <button
                @click="resetCategory"
                :class="{
                  'category-button': true,
                  'active-category': selectedCategory === null,
                }"
              >
                All
              </button>
            </div>
            <div
              v-for="category in categories"
              :key="category.id"
              class="category-wrapper"
            >
              <button
                @click="filterByCategory(category.id)"
                :class="{
                  'category-button': true,
                  'active-category': selectedCategory === category.id,
                }"
              >
                {{ category.name }}
              </button>
            </div>
          </div>
          <div v-else class="no-categories">No categories available.</div>
          <div v-if="hasLanguages" class="language-list">
            <button
              @click="resetLanguage"
              :class="{
                'language-button': true,
                'active-language': !selectedLanguage,
              }"
            >
              All
            </button>
            <button
              v-for="language in displayLanguages"
              :key="language"
              @click="filterByLanguage(language)"
              :class="{
                'language-button': true,
                'active-language': selectedLanguage === language,
              }"
            >
              {{ language }}
            </button>
          </div>
          <div v-else class="no-languages">No languages available.</div>
        </div>
      </div>

      <!-- Tags Section -->
      <div
        class="tags"
        :class="{ hidden: searchActive }"
        v-if="selectedCategory && filteredTags && filteredTags.length"
      >
        <button
          v-for="tag in filteredTags"
          :key="tag.id"
          @click="filterByTag(tag.id)"
          :class="{ 'tag-button': true, 'active-tag': selectedTag === tag.id }"
        >
          {{ tag.name }}
        </button>
      </div>

      <!-- Video Grid Section -->
      <div class="video-grid-container">
        <h2 class="section-title">
          {{
            searchActive
              ? `Search Results for '${activeSearchQuery}'`
              : 'Videos for You'
          }}
        </h2>
        <div class="video-grid">
          <div
            v-if="loading && videos.length === 0"
            class="video-grid-skeleton"
          >
            <div
              v-for="n in videosPerPage"
              :key="n"
              class="skeleton-card"
            ></div>
          </div>
          <div
            v-else-if="filteredVideos && filteredVideos.length > 0"
            class="video-grid-content"
          >
            <div
              class="video-card"
              v-for="(video, index) in filteredVideos"
              :key="video.id"
              @click="handleVideoClick(video)"
            >
              <div
                class="video-container"
                @mouseenter="windowWidth > 768 && startPreview(video.id)"
                @mouseleave="stopPreview(video.id)"
              >
                <img
                  v-if="!video.previewPlaying && !video.isPlaying"
                  :src="getThumbnailUrl(video.youtube_url)"
                  :alt="`${video.title} thumbnail - KalpX video in ${video.default_audio_language || 'multiple languages'}`"
                  :srcset="getThumbnailSrcset(video.youtube_url)"
                  sizes="(max-width: 768px) 320px, 480px"
                  class="video-thumbnail"
                  :loading="index === 0 ? 'eager' : 'lazy'"
                  width="320"
                  height="180"
                />
                <div
                  class="play-icon"
                  v-if="!video.previewPlaying && !video.isPlaying"
                ></div>
                <div
                  v-if="video.previewPlaying || video.isPlaying"
                  class="video-wrapper"
                >
                  <iframe
                    :src="getPreviewUrl(video.youtube_url)"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    class="video-frame"
                  ></iframe>
                  <div
                    v-if="windowWidth > 768"
                    class="click-overlay"
                    @click.stop="openModal(video)"
                  ></div>
                </div>
              </div>
              <div class="video-info">
                <h3 class="video-title">{{ video.title }}</h3>
              </div>
            </div>
          </div>
          <div v-else-if="!loading" class="no-videos">No videos found.</div>
        </div>
      </div>

      <!-- Video Modal (Desktop Only) -->
      <div
        v-if="showModal && windowWidth > 768"
        class="modal-overlay"
        @click="closeModal"
      >
        <div class="modal-content" @click.stop>
          <button class="close-button" @click="closeModal">×</button>
          <iframe
            v-if="selectedVideo"
            :src="getEmbedUrl(selectedVideo.youtube_url)"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            class="modal-video"
          ></iframe>
        </div>
      </div>

      <!-- Loading More Indicator -->
      <div v-if="loadingMore" class="loading-more">
        <div class="spinner"></div>
        <span>Loading more videos...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import axios from '../lib/axios';
import debounce from 'lodash/debounce';

const router = useRouter();
const route = useRoute();

const videos = ref([]);
const categories = ref([]);
const tags = ref([]);
const selectedCategory = ref(null);
const selectedTag = ref(null);
const selectedLanguage = ref(null);
const languageCodeMap = ref({});
const searchQuery = ref('');
const activeSearchQuery = ref('');
const searchActive = ref(false);
const loading = ref(true);
const loadingMore = ref(false);
const currentPage = ref(1);
const videosPerPage = ref(21);
const totalVideos = ref(0);
const nextPageUrl = ref(null);
const hasNextPage = ref(false);
const allVideosLoaded = ref(false);
const showModal = ref(false);
const selectedVideo = ref(null);
const allLanguages = ref([]);
const isChildAnimeFilterActive = ref(false);
const windowWidth = ref(window.innerWidth);

const debouncedFetchVideosForGrid = debounce(fetchVideosForGrid, 300);
const debouncedNavigateToSearch = debounce(navigateToSearch, 300);
const handleScroll = debounce(() => {
  if (loadingMore.value || allVideosLoaded.value) return;

  const scrollPosition = window.scrollY + window.innerHeight;
  const pageHeight = document.documentElement.scrollHeight;
  const threshold = 500;

  if (scrollPosition >= pageHeight - threshold) {
    debouncedFetchVideosForGrid();
  }
}, 100);

const filteredVideos = computed(() => {
  if (searchActive.value) return videos.value;
  let results = videos.value;
  if (!loading.value && !searchActive.value) {
    if (selectedCategory.value) {
      results = results.filter(
        (video) =>
          (video.category?.id || video.category_id) === selectedCategory.value
      );
    }
    if (selectedLanguage.value) {
      const languageCode = languageCodeMap.value[selectedLanguage.value];
      results = results.filter(
        (video) => (video.default_audio_language || '') === languageCode
      );
    }
    if (selectedTag.value) {
      results = results.filter((video) =>
        video.tags?.some((tag) => tag.id === selectedTag.value)
      );
    }
  }
  return results;
});

const filteredTags = computed(() => {
  if (!selectedCategory.value) return [];
  return tags.value.filter((tag) => tag.category_id === selectedCategory.value);
});

const selectedCategoryLanguages = computed(() => {
  if (!selectedCategory.value || !categories.value.length) return [];
  const category = categories.value.find(
    (cat) => cat.id === selectedCategory.value
  );
  return category ? category.languages || [] : [];
});

const displayLanguages = computed(() => {
  return selectedCategory.value
    ? selectedCategoryLanguages.value
    : allLanguages.value;
});

const hasLanguages = computed(
  () =>
    Array.isArray(displayLanguages.value) && displayLanguages.value.length > 0
);

useHead({
  title: computed(() => {
    const baseTitle = 'KalpX - Connect to Your Roots';
    if (searchActive.value)
      return `${baseTitle} | Search: ${activeSearchQuery.value}`;
    if (selectedCategory.value)
      return `${baseTitle} | ${categories.value.find((c) => c.id === selectedCategory.value)?.name} Videos`;
    if (selectedLanguage.value)
      return `${baseTitle} | ${selectedLanguage.value} Videos`;
    if (selectedTag.value)
      return `${baseTitle} | ${tags.value.find((t) => t.id === selectedTag.value)?.name} Videos`;
    return baseTitle;
  }),
  meta: [
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    },
    {
      name: 'description',
      content: computed(() => {
        const baseDesc =
          'Explore a wide range of videos on KalpX, featuring categories, languages, and a Kids Hub for family-friendly content.';
        if (searchActive.value)
          return `Search results for "${activeSearchQuery.value}" on KalpX - videos in multiple languages and categories.`;
        if (selectedCategory.value)
          return `Watch ${categories.value.find((c) => c.id === selectedCategory.value)?.name} videos on KalpX in various languages, including Kids Hub content.`;
        if (selectedLanguage.value)
          return `Discover ${selectedLanguage.value} videos on KalpX across multiple categories.`;
        if (selectedTag.value)
          return `Explore videos tagged with ${tags.value.find((t) => t.id === selectedTag.value)?.name} on KalpX in multiple languages.`;
        return baseDesc;
      }),
    },
    { property: 'og:title', content: computed(() => useHead().title.value) },
    {
      property: 'og:description',
      content: computed(() => useHead().meta[1].content.value),
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: computed(() => window.location.href) },
    { property: 'og:image', content: 'https://kalpx.com/og-image.jpg' },
  ],
  link: [
    {
      rel: 'preconnect',
      href: 'https://img.youtube.com',
    },
    {
      rel: 'preconnect',
      href: 'https://www.youtube.com',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://img.youtube.com/vi/57fToVGXVZg/mqdefault.jpg',
      imagesrcset:
        'https://img.youtube.com/vi/57fToVGXVZg/default.jpg 120w, https://img.youtube.com/vi/57fToVGXVZg/mqdefault.jpg 320w, https://img.youtube.com/vi/57fToVGXVZg/mqdefault.jpg 480w',
      media: '(max-width: 768px)',
    },
  ],
});

async function fetchWithRetry(
  url,
  params = {},
  maxRetries = 3,
  delayMs = 1000
) {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const cacheBuster = new Date().getTime();
      const modifiedParams = { ...params, _cacheBuster: cacheBuster };
      const response = await axios.get(url, {
        params: modifiedParams,
        timeout: 10000,
      });
      if (response.data && typeof response.data === 'object')
        return response.data;
      throw new Error('Unexpected response format');
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt === maxRetries) throw error;
      await new Promise((resolve) => setTimeout(resolve, delayMs * attempt));
    }
  }
}

async function fetchCategoriesWithLanguages() {
  try {
    const response = await fetchWithRetry(
      'https://kalpx.com/api/videos/categories_with_languages/'
    );
    const allowedLanguages = [
      'English',
      'Hindi',
      'Marathi',
      'Gujarati',
      'Tamil',
      'Telugu',
    ];
    categories.value = (response.categories || []).map((category) => ({
      id: category.category_id,
      name: category.category_name,
      languages: (category.languages || []).filter((language) =>
        allowedLanguages.includes(language)
      ),
    }));
    allLanguages.value = (response.all_languages || []).filter((language) =>
      allowedLanguages.includes(language)
    );
    languageCodeMap.value = {
      English: 'en',
      Hindi: 'hi',
      Marathi: 'mr',
      Gujarati: 'gu',
      Tamil: 'ta',
      Telugu: 'te',
    };
    updateSectionPadding();
  } catch (error) {
    console.error('Error fetching categories with languages:', error);
    categories.value = [];
    allLanguages.value = [];
  }
}

async function fetchVideosForGrid() {
  if (loadingMore.value || allVideosLoaded.value) return;
  loadingMore.value = true;
  if (videos.value.length === 0) loading.value = true;

  try {
    const languageCode = selectedLanguage.value
      ? languageCodeMap.value[selectedLanguage.value]
      : undefined;
    let params = {
      page: currentPage.value,
      per_page: videosPerPage.value,
      paginate: 'true',
      child_anime_filter: isChildAnimeFilterActive.value ? 'true' : 'false',
    };

    if (searchActive.value) params.search = activeSearchQuery.value;
    else {
      params.category = selectedCategory.value || undefined;
      params.tag = selectedTag.value || undefined;
      params.language = languageCode;
    }

    const response = await fetchWithRetry(
      'https://kalpx.com/api/videos/list_videos/',
      params
    );
    const newVideos = (response.results?.kalpx_videos || []).map((video) => ({
      ...video,
      previewPlaying: false,
      isPlaying: false,
    }));
    const existingIds = new Set(videos.value.map((v) => v.id));
    const uniqueNewVideos = newVideos.filter(
      (newVideo) => !existingIds.has(newVideo.id)
    );

    if (currentPage.value === 1) videos.value = uniqueNewVideos;
    else videos.value.push(...uniqueNewVideos);

    totalVideos.value = response.count || 0;
    hasNextPage.value = response.next !== null && uniqueNewVideos.length > 0;
    nextPageUrl.value = response.next;
    if (hasNextPage.value) currentPage.value++;
    else allVideosLoaded.value = true;
    updateSectionPadding();
  } catch (error) {
    console.error('Error fetching video data:', error);
    if (videos.value.length === 0) videos.value = [];
    allVideosLoaded.value = true;
  } finally {
    loading.value = false;
    loadingMore.value = false;
  }
}

async function fetchTags() {
  try {
    const response = await fetchWithRetry('https://kalpx.com/api/videos/tags/');
    tags.value = response.tags || [];
    updateSectionPadding();
  } catch (error) {
    console.error('Error fetching tags:', error);
    tags.value = [];
  }
}

function filterByCategory(categoryId) {
  selectedCategory.value = categoryId;
  selectedTag.value = null;
  selectedLanguage.value = null;
  const category = categories.value.find((c) => c.id === categoryId);
  router.push(`/category/${category.name.toLowerCase().replace(/\s+/g, '-')}`);
  resetVideos();
  window.scrollTo(0, 0);
  nextTick(() => updateSectionPadding());
}

function resetCategory() {
  selectedCategory.value = null;
  selectedTag.value = null;
  selectedLanguage.value = null;
  router.push('/');
  resetVideos();
  window.scrollTo(0, 0);
  nextTick(() => updateSectionPadding());
}

function filterByLanguage(language) {
  selectedLanguage.value = language;
  router.push(`/language/${language.toLowerCase().replace(/\s+/g, '-')}`);
  resetVideos();
  window.scrollTo(0, 0);
}

function resetLanguage() {
  selectedLanguage.value = null;
  router.push('/');
  resetVideos();
  window.scrollTo(0, 0);
}

function filterByTag(tagId) {
  selectedTag.value = tagId;
  const tag = tags.value.find((t) => t.id === tagId);
  router.push(`/tag/${tag.name.toLowerCase().replace(/\s+/g, '-')}`);
  resetVideos();
  window.scrollTo(0, 0);
}

function toggleChildAnimeFilter() {
  isChildAnimeFilterActive.value = !isChildAnimeFilterActive.value;
  router.push({
    query: {
      ...route.query,
      child_anime_filter: isChildAnimeFilterActive.value ? 'true' : 'false',
    },
  });
  resetVideos();
  window.scrollTo(0, 0);
}

function resetVideos() {
  videos.value = [];
  currentPage.value = 1;
  allVideosLoaded.value = false;
  hasNextPage.value = false;
  fetchVideosForGrid();
}

function startPreview(videoId) {
  if (windowWidth.value > 768) {
    const video = videos.value.find((v) => v.id === videoId);
    if (video) {
      video.previewPlaying = true;
      playVideo(video);
    }
  }
}

function stopPreview(videoId) {
  if (windowWidth.value > 768) {
    const video = videos.value.find((v) => v.id === videoId);
    if (video) {
      video.previewPlaying = false;
      stopVideo(video);
    }
  }
}

function handleVideoClick(video) {
  if (windowWidth.value <= 768) {
    if (!video.isPlaying) {
      stopAllVideos();
      video.isPlaying = true;
      playVideo(video);
    } else {
      video.isPlaying = false;
      stopVideo(video);
    }
  } else if (!video.previewPlaying) {
    openModal(video);
  }
}

function playVideo(video) {
  const iframe = document.querySelector(
    `iframe[src*="${video.youtube_url.split('v=')[1]?.split('&')[0]}"]`
  );
  if (iframe) {
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      '*'
    );
  }
}

function stopVideo(video) {
  const iframe = document.querySelector(
    `iframe[src*="${video.youtube_url.split('v=')[1]?.split('&')[0]}"]`
  );
  if (iframe) {
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      '*'
    );
  }
}

function stopAllVideos() {
  videos.value.forEach((video) => {
    if (video.isPlaying || video.previewPlaying) {
      video.isPlaying = false;
      video.previewPlaying = false;
      stopVideo(video);
    }
  });
}

function openModal(video) {
  stopAllVideos();
  selectedVideo.value = video;
  showModal.value = true;
  nextTick(() => {
    const iframe = document.querySelector('.modal-video');
    if (iframe) {
      iframe.src = getEmbedUrl(video.youtube_url);
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        '*'
      );
    }
  });
}

function closeModal() {
  showModal.value = false;
  selectedVideo.value = null;
}

function getThumbnailUrl(url) {
  if (!url || !url.includes('watch?v=')) return url;
  const videoId = url.split('v=')[1]?.split('&')[0];
  return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
}

function getThumbnailSrcset(url) {
  if (!url || !url.includes('watch?v=')) return '';
  const videoId = url.split('v=')[1]?.split('&')[0];
  return `https://img.youtube.com/vi/${videoId}/default.jpg 120w, https://img.youtube.com/vi/${videoId}/mqdefault.jpg 320w, https://img.youtube.com/vi/${videoId}/mqdefault.jpg 480w`;
}

function getPreviewUrl(url) {
  if (!url || !url.includes('watch?v=')) return url;
  const videoId = url.split('v=')[1]?.split('&')[0];
  const isMobile = windowWidth.value <= 768;
  const muteParam = isMobile ? 'mute=0' : 'mute=1';
  return `https://www.youtube.com/embed/${videoId}?autoplay=1&${muteParam}&controls=1&rel=0&showinfo=0`;
}

function getEmbedUrl(url) {
  if (!url || !url.includes('watch?v=')) return url;
  const videoId = url.split('v=')[1]?.split('&')[0];
  return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&rel=0&showinfo=0`;
}

function navigateToSearch() {
  if (searchQuery.value.trim()) {
    activeSearchQuery.value = searchQuery.value.trim();
    searchActive.value = true;
    router.push(`/search/${encodeURIComponent(activeSearchQuery.value)}`);
    resetVideos();
    window.scrollTo(0, 0);
  }
}

function goToHome() {
  selectedCategory.value = null;
  selectedTag.value = null;
  selectedLanguage.value = null;
  searchActive.value = false;
  activeSearchQuery.value = '';
  searchQuery.value = '';
  router.push('/');
  resetVideos();
  window.scrollTo(0, 0);
}

function updateSectionPadding() {
  requestAnimationFrame(() => {
    const header = document.querySelector('.categories-container');
    const headerHeight = header ? header.offsetHeight : 0;
    document.documentElement.style.setProperty(
      '--header-height',
      headerHeight + 'px'
    );
  });
}

function handleResize() {
  windowWidth.value = window.innerWidth;
  updateSectionPadding();
}

onMounted(async () => {
  isChildAnimeFilterActive.value = route.query.child_anime_filter === 'true';
  await fetchCategoriesWithLanguages();
  await fetchTags();
  const { slug, query } = route.params || {};
  if (route.path.startsWith('/category/') && slug) {
    const category = categories.value.find(
      (c) => c.name.toLowerCase().replace(/\s+/g, '-') === slug
    );
    selectedCategory.value = category?.id;
  } else if (route.path.startsWith('/language/') && slug) {
    selectedLanguage.value = slug.replace(/-/g, ' ');
  } else if (route.path.startsWith('/tag/') && slug) {
    const tag = tags.value.find(
      (t) => t.name.toLowerCase().replace(/\s+/g, '-') === slug
    );
    selectedTag.value = tag?.id;
  } else if (route.path.startsWith('/search/') && query) {
    searchActive.value = true;
    activeSearchQuery.value = decodeURIComponent(query);
    searchQuery.value = activeSearchQuery.value;
  }
  fetchVideosForGrid();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
  nextTick(() => updateSectionPadding());
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
:root {
  --header-height: 70px; /* Fallback value */
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.categories-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 15px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.branding {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.favicon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.favicon:hover {
  transform: scale(1.1);
}

.branding h1 {
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin: 0;
  display: inline-block;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 30px;
  background: #fbda03;
  color: #1a1a1a;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.cancel-button:hover {
  background: #ffec5c;
}

.search-kids-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 1200px;
  margin: 0 auto 10px;
  flex-wrap: wrap;
  padding: 0 10px;
}

.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 4px;
  flex: 1;
  max-width: 350px;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 2px solid #ccc;
  border-radius: 8px 0 0 8px;
  text-align: left;
  transition: border-color 0.3s ease;
  background: #fff;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

.search-icon {
  padding: 8px 12px;
  font-size: 16px;
  border: none;
  border-radius: 0 8px 8px 0;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-icon:hover {
  background-color: #0056b3;
  color: white;
}

.kids-hub-toggle {
  padding: 4px 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: #666;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.kids-hub-toggle:hover {
  background-color: #ffec5c;
  color: #1a1a1a;
}

.kids-hub-toggle.active-filter {
  background-color: #fbda03;
  color: #1a1a1a;
  border-color: #fbda03;
}

.filters {
  transition: opacity 0.3s ease;
}

.filters.hidden {
  opacity: 0;
  pointer-events: none;
  height: 0;
  margin: 0;
  padding: 0;
}

.categories-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 5px 0;
  gap: 5px;
  scrollbar-width: thin;
  scrollbar-color: #fbda03 #f1f1f1;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.categories-row::-webkit-scrollbar {
  height: 8px;
}

.categories-row::-webkit-scrollbar-thumb {
  background-color: #fbda03;
  border-radius: 4px;
}

.categories-row::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.category-wrapper {
  flex: 0 0 auto;
}

.category-button {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #1a1a1a;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  min-width: 80px;
  max-width: 150px;
}

.category-button:hover {
  background-color: #ffec5c;
  color: #1a1a1a;
}

.active-category {
  background-color: #fbda03 !important;
  color: #1a1a1a !important;
  font-weight: bold;
}

.no-categories {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.language-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 5px 0;
  gap: 5px;
  scrollbar-width: thin;
  scrollbar-color: #fbda03 #f1f1f1;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.language-list::-webkit-scrollbar {
  height: 8px;
}

.language-list::-webkit-scrollbar-thumb {
  background-color: #fbda03;
  border-radius: 4px;
}

.language-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.language-button {
  flex: 0 0 auto;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: #666;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.language-button:hover {
  background-color: #ffec5c;
  color: #1a1a1a;
}

.active-language {
  background-color: #fbda03 !important;
  color: #1a1a1a !important;
  border-color: #fbda03 !important;
}

.no-languages {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  padding: calc(var(--header-height) + 20px) 20px 0;
  transition: opacity 0.3s ease;
  max-width: 1200px;
}

.tags.hidden {
  opacity: 0;
  pointer-events: none;
}

.tag-button {
  padding: 8px 16px;
  margin: 5px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #f4f4f4;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.tag-button:hover {
  background-color: #0056b3;
  color: white;
}

.active-tag {
  background-color: #007bff !important;
  color: white !important;
}

.video-grid-container {
  margin-top: 10px;
  padding: calc(var(--header-height) + 20px) 20px 0;
}

.video-grid {
  max-width: 1200px;
  margin: 0 auto;
  min-height: 600px;
}

.video-grid-skeleton,
.video-grid-content {
  display: grid;
  gap: 20px;
  padding: 10px 0;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.skeleton-card {
  width: 100%;
  aspect-ratio: 16 / 9;
  background: #e0e0e0;
  border-radius: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.video-card {
  width: 100%;
  transition: transform 0.3s ease;
}

.video-card:hover {
  transform: scale(1.05);
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-frame {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.click-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.play-icon:hover {
  opacity: 0.8;
}

.play-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.video-info {
  padding: 10px 0;
  text-align: center;
  min-height: 60px;
}

.video-title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  cursor: pointer;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.section-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #1a1a1a;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
}

.loading-more {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid #fbda03;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-videos {
  text-align: center;
  font-size: 18px;
  color: #666;
  padding: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90vh;
  overflow: auto;
}

.modal-video {
  width: 800px;
  height: 450px;
  border: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: #e60000;
}

/* Media queries */
@media (max-width: 1024px) {
  .modal-video {
    width: 600px;
    height: 337px;
  }
}

@media (max-width: 480px) {
  .category-button {
    padding: 6px 12px;
    font-size: 14px;
    min-width: 60px;
    max-width: 120px;
  }

  .language-button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .section-title {
    font-size: 18px;
  }

  .video-title {
    font-size: 14px;
  }

  .search-input {
    font-size: 14px;
  }

  .branding h1 {
    font-size: 16px;
  }

  .video-grid-container {
    padding: calc(var(--header-height) + 20px) 10px 0;
  }

  .favicon {
    width: 20px;
    height: 20px;
  }
}
</style>
