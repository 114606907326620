<template>
  <div>
    <h1 v-if="video">Video Details: {{ video.title }}</h1>
    <div v-else>Loading video details...</div>

    <div v-if="video">
      <img :src="video.thumbnail" alt="Video Thumbnail" />
      <p>{{ video.description }}</p>

      <!-- Embed YouTube video using the original youtube_url -->
      <iframe
        :src="getEmbedUrl(video.youtube_url)"
        frameborder="0"
        allowfullscreen
        width="560"
        height="315"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from '../lib/axios'; // Import axios or your axios instance

export default {
  data() {
    return {
      video: null, // To store video details
    };
  },
  created() {
    this.fetchVideoDetails();
  },
  methods: {
    async fetchVideoDetails() {
      try {
        const videoId = this.$route.params.id; // Get the video ID from the route parameters
        const response = await axios.get(
          `https://kalpx.com/api/videos/${videoId}/video/`
        );
        this.video = response.data; // Assign the response to the video data
      } catch (error) {
        console.error('Error fetching video details:', error);
      }
    },
    // Method to convert youtube_url to embed URL
    getEmbedUrl(url) {
      const videoId = url.split('v=')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
};
</script>

<style scoped>
/* Add your styling here */
iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  margin: 20px 0;
}
</style>
