<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="layout-container">
    <main class="main-content">
      <slot></slot>
    </main>
    <footer class="footer">
      <p>© 2025 KalpX</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Layout',
};
</script>

<style scoped>
.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f5f7fa; /* Light background to complement the footer */
}

.main-content {
  flex: 1 0 auto;
  padding-bottom: 20px;
}

.footer {
  background: linear-gradient(
    90deg,
    #fbda03,
    #ffec5c
  ); /* Bright yellow gradient */
  color: #1a1a1a;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  margin-top: auto;
}

.footer p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
</style>
