import axios from 'axios';

const api = axios.create({
  baseURL: 'https://kalpx.com/api/videos/', // Base URL of the KalpX API
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
