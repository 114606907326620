import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head'; // Replace vue-meta

const app = createApp(App);
const head = createHead();

app.use(router); // Register Vue Router
app.use(head); // Register head management for SEO

// Set global OpenAI API key
app.config.globalProperties.$OPENAI_API_KEY =
  process.env.VUE_APP_OPENAI_API_KEY || '';

app.mount('#app');
