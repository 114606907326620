import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import VideoDetail from '../views/VideoDetail.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/category/:slug', component: Home, props: true }, // Dynamic category filter
  { path: '/language/:slug', component: Home, props: true }, // Dynamic language filter
  { path: '/tag/:slug', component: Home, props: true }, // Dynamic tag filter
  { path: '/search/:query', component: Home, props: true }, // Dynamic search
  {
    path: '/video/:id',
    component: VideoDetail,
    name: 'VideoDetail',
    props: true,
  }, // Video detail
  // Optional: Separate search page if implemented later
  // { path: '/search', name: 'Search', component: Search },
];

const router = createRouter({
  history: createWebHistory(), // Clean URLs (requires CloudFront/S3 SPA config)
  routes,
});

export default router;
