<template>
  <Layout>
    <router-view />
    <!-- This will display the page content -->
  </Layout>
</template>

<script>
import Layout from './components/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>
